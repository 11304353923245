const redirects = {
   'url1' : { landing_page : 'https://soltechenergy.com/?utm_source=Mail&utm_medium=reaktion&utm_campaign=hostkampanj_B2B&utm_content=nyhetsbrevsnamn', name: "Huvudsida" },
   'url2' : { landing_page : 'https://soltechenergy.com/notis/nya-lagkrav-pa-solceller-vi-reder-ut-vad-som-galler/?utm_source=Mail&utm_medium=reaktion&utm_campaign=hostkampanj_B2B&utm_content=nyhetsbrevsnamn', name: "Nya lagkrav på solceller" },
   'url3' : { landing_page : 'https://soltechenergy.com/notis/helhetslosningar-for-en-energismart-fastighet/?utm_source=Mail&utm_medium=reaktion&utm_campaign=hostkampanj_B2B&utm_content=nyhetsbrevsnamn', name: "Helhetslösningar för en energismart fastighet" },
   'url4' : { landing_page : 'https://soltechenergy.com/notis/fasader-for-en-ljusare-framtid/?utm_source=Mail&utm_medium=reaktion&utm_campaign=hostkampanj_B2B&utm_content=nyhetsbrevsnamn', name: "Solcellsfasader för en ljusare framtid" },
   'url5' : { landing_page : 'https://soltechenergy.com/notis/laddningslosningar-for-alla-behov/?utm_source=Mail&utm_medium=reaktion&utm_campaign=hostkampanj_B2B&utm_content=nyhetsbrevsnamn', name: "Laddningslösningar för alla behov" },
   'url6' : { landing_page : 'https://soltechenergy.com/kontaktformular/?utm_source=Mail&utm_medium=reaktion&utm_campaign=hostkampanj_B2B&utm_content=nyhetsbrevsnamn', name: "Kontakta oss" },

   'url7' : { landing_page : 'https://soltechenergy.com/?utm_source=Mail&utm_medium=reaktion&utm_campaign=hostkampanj_B2B&utm_content=notiser-december', name: "Huvudsida" },
   'url8' : { landing_page : 'https://soltechenergy.com/notis/arkitektens-ord-om-de-nya-eu-direktiven/?utm_source=Mail&utm_medium=reaktion&utm_campaign=hostkampanj_B2B&utm_content=notiser-december', name: "Arkitektens ord om de nya EU-direktiven" },
   'url9' : { landing_page : 'https://soltechenergy.com/notis/solel-och-biologisk-mangfald-i-ett/?utm_source=Mail&utm_medium=reaktion&utm_campaign=hostkampanj_B2B&utm_content=notiser-december', name: "Solel och biologisk mångfald i ett" },
   'url10' : { landing_page : 'https://soltechenergy.com/notis/sakra-och-lonsamma-energianlaggningar-med-vara-serviceavtal/?utm_source=Mail&utm_medium=reaktion&utm_campaign=hostkampanj_B2B&utm_content=notiser-december', name: "Säkra och lönsamma energianläggningar med våra serviceavtal" },
   'url11' : { landing_page : 'https://soltechenergy.com/kontaktformular/?utm_source=Mail&utm_medium=reaktion&utm_campaign=hostkampanj_B2B&utm_content=notiser-december', name: "Kontakta oss" },
};

export default redirects